import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import SimpleNavar from './../components/SimpleNavbarComponent/SimpleNavbar';
import Footer from './../components/FooterComponent/Footer';
class Terms extends Component {
  state = {};
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>TOKINFO TERMS AND CONDITIONS OF USE</title>
        </Helmet>
        <SimpleNavar />

        <div className="navbar-bg-with-bottom"></div>

        <div className="privacy-wrapper pt-5">
          <div className="container">
            <h2>TOKINFO TERMS AND CONDITIONS OF USE</h2>
            <h4>Last Updated June 15, 2020</h4>
            <ol>
			<li>
				<h3><u>Overview of Terms</u></h3>
					<p>This Terms and Conditions of Use (these <b>“Conditions of Use”</b>) governs your use of Tokinfo – 
					whether through the website at <a href="http://www.tokinfo.com">www.tokinfo.com</a> or the Tokinfo app. Tokinfo is provided  
					to you by DevSpike LLC (also <b>“us”</b> or <b>“we”</b> or <b>“our”</b>) with the base of operation in the United 
					States. </p>

					<p>Tokinfo is provided as a platform that enables the safe passage of important information
					from one person to another. Tokinfo is not created for the passage of illegal information
					related to wars, rebellious acts, crisis, coup, government takeovers, and terrorist attacks.
					Therefore, by using Tokinfo, you represent to us that any information passed is legal. If we 
					have a good faith belief that you are using Tokinfo illegally, we have the right to block you
					and also report you to the relevant government.</p>

					<p>You must read these Conditions of Use along with our <a href="https://tokinfo.com/privacy">Privacy Policy</a>, which describes our
					activities regarding the information we collect from you. <b>By using Tokinfo, you 
					acknowledge and agree to these Conditions of Use. If you do not agree to these 
					Conditions of Use, you are not permitted to use or continue using Tokinfo.</b></p>
				</li>

			<li>
				<h3><u>How Tokinfo Works</u></h3>
					<p>DevSpike LLC provides Tokinfo as a platform where private information can be passed from
					one person to another. An individual can access Tokinfo to type a private message or 
					information in the box provided togenerate a token number. The token number may then 
					be given to whoever such person wishes to have access to any information contained in the
					token number generated.Any token number generated through Tokinfo has a maximum
					number of 48 hours before Tokinfo automatically deletes it. However, the user can always 
					choose their preferred expiry time between 12 hours, 24 hours, and 48 hours. If you 
					generate a token number and 48 hours is up, it will automatically be deleted. Therefore,
					DevSpike LLC will not be liable to you if Tokinfo automatically deletes any information that
					is passed the 48-hour window. Alternatively, a user can also set the message to be deleted 
					from the server when one person has accessed the information in the token number. if this
					is the case, the information will automatically be deleted from our server after the first 
					read, even if the expiry time you set is not up.</p>
			</li>
				
			<li>
				<h3><u>Your Use of Tokinfo</u></h3>	
					<p>You agree that you will only use Tokinfo for the purposes for which it is made available. You 
					shall not use it for any illegal activities that are against these Conditions of Use and any 
					applicable law. You agree to stay away from the below-prohibited activities when you use 
					Tokinfo: </p>
					
					a.	Attempting to bypass any measures designed on Tokinfo to prevent or restrict you from <br/>
						accessing certain portion thereof;<br/>
					b.	Attempting to gain access to any information or message through automated means,<br/>
						including by using robots, spiders, and other data mining or extraction software;<br/>
					c.	Using Tokinfo as a means to pass illegal information or as a tool to communicate with<br/>
						terrorists and other crime;<br/>
					d.	Deciphering, decompiling, disassembling, or reverse-engineering any of the software <br/>
						comprising or in any way making up a part of Tokinfo;<br/>
					e.	Interfering with, disrupting, or creating an undue burden on Tokinfo or the services <br/>
						connected to it; and<br/>
					f.	Accessing and using Tokinfo as part of any effort to compete with DevSpike LLC.<br/>
			</li>

			<li>
				<h3><u>Intellectual Property Rights</u></h3>
					<p>The content provided by Tokinfo (the <b>“Tokinfo Content”</b>) and the trademarks, service
					marks, and logos contained therein (<b>“Marks”</b>) are owned by or licensed to us, and are 
					subject to copyright and other intellectual property rights under the United States,
					Bangladesh, and international conventions. The Tokinfo Content includes, without
					limitation, the source codes, databases, functionalities, software, app and web designs,
					audios, videos, texts, photographs, and graphics. Our trademarks and trade dress may not
					be used, including as part of trademarks and as part of domain names, in connection with
					any product or service in any way that may bring confusion, and may not be copied,
					imitated, or used, in whole or part, without our prior written permission.</p>
			</li>
				
			<li>
				<h3><u>License Grant</u></h3>
					<p>Provided that you are eligible to use Tokinfo, we grant you alimited license to access and 
					use it, including the Tokinfo Content,Tokinfo app, and website solely for your personal, non-
					commercial use. We reserve all rights not expressly granted in relation to the Tokinfo app,
					the website, Tokinfo Content, and Marks.</p>
			</li>
				
			<li>
				<h3><u>App Distributor Agreement</u></h3>	
					<p>This section applies to you if you download the Tokinfoapp from either the Apple Store or Google
					Play Stores (each an <b>“App Distributor”</b>). You acknowledge that the Tokinfo app is established
					between you and DevSpike LLC and not with any App Distributor, and that the content and service
					provided through the Tokinfo app is DevSpike LLC’s responsibility.</p>
					
					<ul>
						<li><p><b>License and Scope of the Tokinfo app:</b> The license granted to you to use the Tokinfo app 
						is limited to non-transferable permission to use it on a device that uses the Apple iOS or 
						Android operating system (as applicable) and in accordance with the usage rules set forth in the
						applicable App Distributor terms and conditions.</p></li>
						<li><p><b>Maintenance and Support:</b> You agree that it is the duty of DevSpike LLC to provide maintenance 
						and support activities in relation to the Tokinfo app.</p></li>
						<li><p><b>Warranty:</b> You agree that it is DevSpike LLC’s responsibility to provide any product warranties,
						whether expressed or implied by law, to the extent not effectively disclaimed.Should the Tokinfo
						app fail to conform to any applicable warranty, the liability shall be that of DevSpike LLC and not
						the relevant App Distributor. The only warranty obligation the relevant App Distributor may have 
						is the refund of the purchase price of the Tokinfo app (if any), and such refunds will be in 
						accordance with the App Distributor’s terms and conditions.</p> </li>
						<li><p><b>Product Claims:</b> You agree that DevSpike LLC shall be liable for any claims you or third 
						parties may have regarding the Tokinfo app, including product liability claims and consumer 
						protection claims (or similar legislation).</p></li>
						<li><p><b>Intellectual Property Rights:</b> You agree that any third party claims relating to your use of
						the Tokinfo app infringe on the intellectual property of a third party, the relevant App Distributor 
						will not be responsible for investigating, defending, settling, or discharging any infringement 
						claims.</p></li>
						<li><p><b>Legal Compliance:</b>You represent to us that you are not using Tokinfo from any country that is
						subject to the United States government embargo or designated by the United States government 
						as a terrorist-supporting country or otherwise prohibited by the United States.</p></li>
						<li><p><b>Third Party Terms and Conditions:</b> You agree that you will comply with any third party app
						terms and conditions while you use the Tokinfo app. for example, if you use a VoIP app, you 
						must comply with the terms and conditions of such a VoIP app.</p></li>
						<li><p><b>Third Party Beneficiary:</b>Youand DevSpike LLC acknowledges that both App Distributors are
						third party beneficiaries of this agreement, and that upon your acceptance of these Conditions of 
						Use,both App Distributors will have the right to enforce this agreement against you as a third party
						beneficiary.</p></li>
					</ul>
			</li>
			
			<li>
				<h3><u>Third Party Sites</u></h3>
					<p>Tokinfo may occasionally contain adverts from other websites (“Third Party Sites”) due to 
					the presence of Google AdSense. Third Party Sites are not in any way affiliated to Tokinfo; 
					therefore, any content or link posted by Third Party Sites is not checked by us for accuracy
					neither do we endorse them to be safe. If you access or use any content that belongs to a
					Third Party Site on Tokinfo, you do so at your own discretion, and you release us from any 
					losses, damages, claims, or liabilities you may suffer from such content. We recommend that
					you read the terms and policies of content posted by Third Party Sites through Tokinfo before
					you access them.</p>
			</li>
				
			<li>
				<h3><u>Privacy Policy</u></h3>
					<p>When you use Tokinfo, you are not required to submit any personal information. However, we
					collect certain anonymous information through certain software. Also, the token number you
					generate on Tokinfo may only be used, shared, or protected as described in our 
					<a href="https://tokinfo.com/privacy">Privacy Policy.</a> </p>
			</li>
			
			<li>
				<h3><u>Disclaimer of Warranty</u></h3>
					<p>We cannot control the proper functioning of Tokinfo at all times. Certain circumstances may 
					render it to be unavailable or not usable at some point. Therefore, you hereby agree that your 
					use of Tokinfo is at your sole risk. To the fullest extent permissible under applicable law, 
					DevSpike LLC (including subsidiaries and affiliates) disclaims all warranties (express 
					or implied) in relation to your use of Tokinfo, including, without limitation, warranties of
					merchantability, non-infringement, and fitness for a specific purpose.</p> 

					<p>DevSpike LLC does not warrant or represent that Tokinfo will be, at all times, available and 
					that it will operate to your expectation. Therefore, we shall not be liable to you for any(i) errors,
					omissions, inaccuracies, and mistakes of any Tokinfo Content (ii) damages, injuries, or losses  
					incurred from your use (iii) access to your information by theft or hacks (iv) failure or delay
					of Tokinfo to work at a particular time (v) viruses, bugs, trojan horses, or the likes,which may
					be transmitted by third parties, and (vi) Third Party Site you accessed through Tokinfo.</p>
			</li>
			
			<li>
				<h3><u>Limitation of Liability</u></h3>
					<p>Under no circumstances shall DevSpike LLC be liable to you or any third party for damages (whether
					direct, indirect, consequential, exemplary, incidental, social, or punitive damages), including but 
					not limited to damages or loss of profit, revenue, goodwill, information or data, and other damages 
					you may suffer from your use of Tokinfo, even if we may have had a prior notice about such damages 
					occurring.</p>

					<p>Notwithstanding the foregoing, if DevSpike LLC is found liable for any cause whatsoever in relation 
					to your use of Tokinfo, our liability shall not exceed the sum of $100 (one hundred dollars).</p> 

					<p>Some states or country laws do not permit the limitations of implied warranties or the exclusion of 
					specific damages. If these laws apply to you, some or all of the limitations above may not affect  
					you, and you may have added rights.</p>

					<p>If you are using Tokinfo from the State of California, you may waive California civil code 1542, 
					which states that “a general release does not include claims which the creditor does not know or  
					suspect to exist in his favor, which if known by him, must have materially affected his settlement
					with the debtor.” </p>
			</li>
			
			<li>
				<h3><u>Indemnification</u></h3>
					<p>You now agree to indemnify, defend, and hold DevSpike LLC harmless from and against any damages, 
					losses, demands, claims, liabilities, and expenses (including reasonable attorney’s fees) that is made by
					any third party due to your use of Tokinfo, your breach of these Conditions of Use, and your warranties
					and representations.</p>
			</li>
			
			<li>
				<h3><u>Governing Law</u></h3>
					<p>These Conditions of Use and any issue arising from your use of Tokinfo shall be governed by and construed
					in accordance with the laws of the State ofWyoming without regard to conflicts of law and its principles.</p>
			</li>
			<li>
				<h3><u>Miscellaneous</u></h3>
					<ul>
						<li><p>These Conditions of Use represent the entire agreement between you and us regarding your use of 
						Tokinfo and it supersedes any and all contemporaneous agreements and understanding that may have 
						existed between us prior to it. </p> </li>
						<li><p>Should DevSpike LLC fail or delay to enforce our rights under these Conditions of Use at any 
						time, it shall not serve as a waiver of our rights to enforce such rights at a later time or on
						subsequent breaches.</p></li>
						<li><p>The headings under these Conditions of Use are for convenience only and have no legal or
						contractual effect.</p></li>
						<li><p>DevSpike LLC shall not be liable to you or any third party for any delay or failure to
						provide Tokinfo if such a delay or failure is caused by circumstances beyond our control, including,
						without limitation, wars, government actions, severe weather conditions, pandemics, riots, and acts
						of God.</p></li>
						<li><p>If any portion of these Conditions of Use is found by a court of competent jurisdiction to 
						be invalid or unenforceable, such a portion will be deemed severable, and it shall not affect the 
						validity and enforceability of the remaining portions.</p></li>
						<li><p>You hereby waive any defenses you may have based on the electronic form of these Conditions 
						of Use and the lack of signing to execute it by you and DevSpike LLC.</p></li>
					
					
					</ul>
			</li>
	
			<li>
				<h3><u>Contact Us</u></h3>
					<p>For any questions, complaints, inquiries, and suggestions regarding your use of Tokinfo and 
					these Conditions of Use, please use the contact information provided below:</p>
			

					<h3>DevSpike LLC</h3>
					
					<i>1309 Coffeen Avenue STE 1200</i><br/>
					<i>Sheridan, Wyoming 82801</i><br/>
					<i>+1 (989) 317-2229 (USA)</i><br/>
					<i>+8801711731169 (WhatsApp)</i><br/>
			</li>

		</ol>
          </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    );
  }
}

export default Terms;
