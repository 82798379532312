import React, { Component } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import LogoTop from './../../images/logo1.png';

class SimpleNavbar extends Component {
  state = {
    scrolled: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100;

      if (isTop !== true) {
        this.setState({
          scrolled: true,
        });
      } else {
        this.setState({
          scrolled: false,
        });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', function () {});
  }

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    return (
      <nav
        className={
          this.state.scrolled === false
            ? 'navbar navbar-expand-lg navbar-light navbar-wrapper'
            : 'navbar navbar-expand-lg navbar-light navbar-wrapper scrolled'
        }
        id="navbar"
      >
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={LogoTop} alt="" />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link home" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link home" href="/terms">
                  Terms
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link home" href="/privacy">
                  Privacy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default SimpleNavbar;
